import React from 'react'

function ArrowLeftIcon({
   width = "14",
   height = "23",
   fill = "black"
}) {
   return (
      <svg width={width} height={height} viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M12 2L2.90479 11.0952L12 21.1" stroke={fill} strokeWidth="3.58125" />
      </svg>
   )
}

export default ArrowLeftIcon