import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { format } from 'date-fns'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useSnackbar } from 'context/SnackbarContext'

import MainContainer from 'components/layout/container/MainContainer'

import CalendarIcon from 'assets/CalendarIcon'
import CopyIcon from 'assets/CopyIcon'

import api from 'services/axios';

function Redeem() {

   const { id } = useParams();
   const openSnackbar = useSnackbar();

   const [isLoading, setIsLoading] = useState(false)
   const [claim, setClaim] = useState({})

   const getVoucherHistories = async () => {
      setIsLoading(true)
      try {
         const { data: { result } } = await api.get(`/api/clm/${id}`, {
            headers: {
               'access-token': `${window.localStorage.getItem('access_token')}`
            }
         });
         setClaim(result)
         setIsLoading(false)
      } catch (error) {
         setIsLoading(false)
         const errMessage =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
         openSnackbar(errMessage.split('-').join(' '), 'error');
         window.location.replace('/login')
      }
   }

   useEffect(() => {
      getVoucherHistories()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <MainContainer isLoading={isLoading}>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               '& > :not(style)': {
                  m: '0 -34px'
               },
               gap: '14px'
            }}
         >
            <Paper
               elevation={0}
               sx={{
                  p: '30px 46px',
                  display: 'flex',
                  flexDirection: 'column',
               }}
            >
               <Typography variant="h5" sx={{ fontSize: 30, fontWeight: 'bold', mb: '35px' }}>Redeem Success</Typography>
               <Box
                  sx={{
                     display: 'flex',
                     gap: '10px',
                     mb: '25px'
                  }}
               >
                  <CalendarIcon />
                  {
                     claim &&
                     claim.createdAt &&
                     <Typography>{format(new Date(claim.createdAt), "EEEE, dd LLLL y KK:mm aa")}</Typography>
                  }
               </Box>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     gap: '5px',
                     mb: '15px'
                  }}
               >
                  <Typography sx={{ color: '#5D2FE1', fontWeight: "bold" }}>Transaction ID</Typography>
                  <Typography>{claim && claim.claim_reference}</Typography>
               </Box>
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     gap: '5px',
                     mb: '15px'
                  }}
               >
                  <Typography sx={{ color: '#5D2FE1', fontWeight: "bold" }}>Description</Typography>
                  <Typography>
                     {
                        claim &&
                        claim.vouchers &&
                        claim.vouchers[0] &&
                        claim.vouchers[0].voucher &&
                        claim.vouchers[0].voucher.game &&
                        claim.vouchers[0].voucher.game.description
                     }
                  </Typography>
               </Box>
            </Paper>
            <Paper
               elevation={0}
               sx={{
                  p: '30px 46px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px'
               }}
            >
               <Typography sx={{ color: '#5D2FE1', fontWeight: "bold", fontSize: '18px' }}>Voucher Code</Typography>
               {
                  claim &&
                  claim.vouchers &&
                  claim.vouchers.map((voucher, i) => (
                     <Grid
                        key={i}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                           background: '#E8F4FF',
                           borderRadius: '10px',
                           p: '16px 20px'
                        }}
                     >
                        <Grid item xs={3}>
                           <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{voucher.voucher_code}</Typography>
                        </Grid>

                        <Grid
                           item
                           xs={6}
                           sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              gap: '16px'
                           }}
                        >
                           <Button
                              sx={{ minWidth: 'unset' }}
                              onClick={() => {
                                 navigator.clipboard.writeText(voucher.voucher_code)
                                 openSnackbar('Copied to clipboard', 'success')
                              }}>
                              <CopyIcon />
                           </Button>
                        </Grid>
                     </Grid>
                  ))
               }
            </Paper>
         </Box>
      </MainContainer>
   )
}

export default Redeem