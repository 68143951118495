import React from 'react'

function AvatarIcon({
   width = '31',
   height = '31',
   fill = "#2F6CE1"
}) {
   return (
      <svg width={width} height={height} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M15.4455 6.61951C14.3545 6.61951 13.2879 6.94303 12.3808 7.54917C11.4736 8.1553 10.7666 9.01682 10.3491 10.0248C9.93159 11.0328 9.82235 12.1419 10.0352 13.2119C10.248 14.282 10.7734 15.2649 11.5449 16.0364C12.3163 16.8078 13.2992 17.3332 14.3693 17.546C15.4393 17.7589 16.5485 17.6496 17.5565 17.2321C18.5644 16.8146 19.4259 16.1076 20.0321 15.2004C20.6382 14.2933 20.9617 13.2268 20.9617 12.1358C20.9617 10.6728 20.3806 9.26968 19.3461 8.23518C18.3116 7.20068 16.9085 6.61951 15.4455 6.61951ZM15.4455 15.4455C14.7909 15.4455 14.151 15.2514 13.6067 14.8877C13.0624 14.5241 12.6382 14.0071 12.3876 13.4024C12.1371 12.7976 12.0716 12.1321 12.1993 11.4901C12.327 10.848 12.6422 10.2583 13.1051 9.79542C13.568 9.33254 14.1577 9.01732 14.7998 8.88961C15.4418 8.7619 16.1073 8.82745 16.7121 9.07795C17.3168 9.32846 17.8338 9.75268 18.1974 10.297C18.5611 10.8413 18.7552 11.4812 18.7552 12.1358C18.7544 13.0133 18.4054 13.8546 17.7849 14.4752C17.1643 15.0957 16.323 15.4447 15.4455 15.4455Z" fill={fill} />
         <path d="M15.4455 0C12.3907 0 9.40447 0.905865 6.86446 2.60304C4.32446 4.30022 2.34476 6.71248 1.17573 9.53479C0.00669229 12.3571 -0.299181 15.4627 0.296789 18.4588C0.892758 21.455 2.3638 24.2071 4.5239 26.3672C6.684 28.5273 9.43613 29.9983 12.4323 30.5943C15.4284 31.1903 18.534 30.8844 21.3563 29.7154C24.1786 28.5463 26.5909 26.5666 28.288 24.0266C29.9852 21.4866 30.8911 18.5004 30.8911 15.4455C30.8864 11.3506 29.2576 7.42464 26.362 4.52905C23.4664 1.63347 19.5405 0.00467197 15.4455 0V0ZM8.82603 26.894V25.3748C8.82691 24.4973 9.17589 23.6559 9.7964 23.0354C10.4169 22.4149 11.2583 22.0659 12.1358 22.0651H18.7553C19.6328 22.0659 20.4742 22.4149 21.0947 23.0354C21.7152 23.6559 22.0642 24.4973 22.0651 25.3748V26.894C20.0562 28.067 17.7718 28.6851 15.4455 28.6851C13.1193 28.6851 10.8349 28.067 8.82603 26.894V26.894ZM24.2627 25.2932C24.2408 23.8461 23.6512 22.4655 22.621 21.449C21.5908 20.4324 20.2026 19.8613 18.7553 19.8586H12.1358C10.6885 19.8613 9.30025 20.4324 8.27009 21.449C7.23993 22.4655 6.65034 23.8461 6.62835 25.2932C4.62767 23.5067 3.21679 21.1547 2.58254 18.5486C1.94828 15.9425 2.12057 13.2052 3.07658 10.6992C4.03259 8.19317 5.72724 6.03663 7.93612 4.51514C10.145 2.99364 12.7639 2.17895 15.4461 2.17895C18.1283 2.17895 20.7472 2.99364 22.9561 4.51514C25.165 6.03663 26.8596 8.19317 27.8156 10.6992C28.7716 13.2052 28.9439 15.9425 28.3097 18.5486C27.6754 21.1547 26.2645 23.5067 24.2638 25.2932H24.2627Z" fill={fill} />
      </svg>
   )
}

export default AvatarIcon