import Snackbar from 'components/alert/Snackbar'
import { createContext, useState, useContext } from 'react';

export const SnackbarContext = createContext();

export function useSnackbar() {
   return useContext(SnackbarContext)
}

export function SnackbarProvider({ children }) {
   const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'success',
      duration: 2500
   });

   const { open, message, severity, duration } = snackbar;

   const openSnackbar = (
      message,
      severity = 'success',
      duration = 2500
   ) => {
      setSnackbar({
         open: true,
         message: message,
         severity: severity,
         duration: duration
      })
   }

   const handleClose = () => {
      setSnackbar({
         ...snackbar,
         open: false,
      })
   }
   return (
      <SnackbarContext.Provider value={openSnackbar}>
         <Snackbar
            open={open}
            message={message}
            severity={severity}
            onClose={handleClose}
            autoHideDuration={duration}
         />
         {children}
      </SnackbarContext.Provider>
   );
}