import { createContext, useState, useContext } from 'react';

export const UserContext = createContext();

export function useUser() {
   return useContext(UserContext)
}

export function UserProvider({ children }) {
   const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('userAccount')))

   return (
      <UserContext.Provider value={user}>
         {children}
      </UserContext.Provider>
   );
}