import React from 'react'

function CircleCheckIcon({
   width = "16",
   height = "16",
   fill = "white"
}) {
   return (
      <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M5.33325 8.33325L7.33325 10.3333L10.6666 6.33325" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
         <path d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6818 1.33325 7.99992 1.33325C4.31802 1.33325 1.33325 4.31802 1.33325 7.99992C1.33325 11.6818 4.31802 14.6666 7.99992 14.6666Z" stroke={fill} strokeWidth="1.5" />
      </svg>
   )
}

export default CircleCheckIcon