import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar';

import { useSnackbar } from 'context/SnackbarContext'
import { useUser } from 'context/UserContext'
import api from 'services/axios'
import MainContainer from 'components/layout/container/MainContainer'
import ProfileForm from 'views/Profile/ProfileForm/ProfileForm'

function Profile() {
   const openSnackbar = useSnackbar();
   const user = useUser();

   const [channelRef, setChannelRef] = useState(user?.channelReference)
   const [agentRef, setAgentRef] = useState(user?.agentReference)

   const userName = user?.emailAddress?.split('@')[0] || 'user'

   // useEffect(() => {
   //    const accessToken = window.localStorage.getItem('access_token')
   //    accessToken && getUserProfile(accessToken)
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [])

   return (
      <MainContainer>
         <Box
            sx={{
               marginTop: '30px'
            }}
         >
            <Typography
               variant='h2'
               sx={{
                  fontSize: '30px',
                  fontWeight: 'bold',
                  mb: '40px'
               }}
            >
               My Profile
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '14px', mb: '30px' }}>
               <Avatar alt="user_avatar" src="/images/user_avatar.png" sx={{ width: 68, height: 68, border: '2px solid #2F6CE1' }} />
               <Box>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5D2FE1' }}>{userName}</Typography>
                  <Typography variant="body1">Jakarta, Indonesia</Typography>
               </Box>
            </Box>
            <ProfileForm
               userEmail={user?.emailAddress}
               channelRef={channelRef}
               agentRef={agentRef}
            />
         </Box>
      </MainContainer>
   )
}

export default Profile