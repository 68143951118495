import React, { useState, useEffect } from 'react'

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import api from 'services/axios';

import SearchIcon from 'assets/SearchIcon'
import ArrowDownIcon from 'assets/ArrowDownIcon'

import { useLoading } from 'context/LoadingContext'
import { useSnackbar } from 'context/SnackbarContext'

function SearchBar({
   handleSearch,
   handleClear,
   searchableGames
}) {

   const CustomPaper = (props) => {
      return (
         <Paper
            {...props}
            elevation={0}
            sx={{
               mt: '10px',
               boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
               borderRadius: '15px',
               p: '10px 0'
            }}
         />
      )
   };

   return (
      <Box sx={{ width: '100%' }} >
         <Autocomplete
            id="games-search"
            options={searchableGames}
            onChange={(event, value) => handleSearch(value)}
            onInputChange={(event, value, reason) => {
               if (reason === 'clear') {
                  handleClear()
               }
            }}
            sx={{
               '& .MuiInputBase-root': {
                  padding: '16px 20px',
                  borderRadius: '16px',
                  background: 'white'
               },
               '& .MuiAutocomplete-popupIndicator': {
                  marginRight: '12px'
               }
            }}
            PaperComponent={CustomPaper}
            popupIcon={(
               <ArrowDownIcon />
            )}

            renderInput={(params) => (
               <TextField
                  {...params}
                  placeholder='Search games here...'
                  InputProps={{
                     ...params.InputProps,
                     startAdornment: (
                        <InputAdornment position="start">
                           <SearchIcon />
                        </InputAdornment>
                     ),

                  }}
               />
            )}
            renderOption={(props, option) => (
               <Box
                  {...props}
                  sx={{
                     p: '12px 30px !important',
                     m: '0px !important',
                     '&:hover': {
                        backgroundColor: '#EFEAFF!important',
                        fontWeight: 'bold',
                     }
                  }}
               >
                  {option.label}
               </Box>
            )}
         />
      </Box>
   )
}

export default SearchBar