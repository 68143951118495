import React from 'react'

function CloseIcon({
   width = "10",
   height = "10",
   fill = "rgba(0, 0, 0, 0.5)",
}) {
   return (
      <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M6.05745 5.00002L9.28245 1.78252C9.42368 1.64129 9.50302 1.44974 9.50302 1.25002C9.50302 1.05029 9.42368 0.858745 9.28245 0.717517C9.14123 0.576289 8.94968 0.496948 8.74995 0.496948C8.55023 0.496948 8.35868 0.576289 8.21745 0.717517L4.99995 3.94252L1.78245 0.717517C1.64123 0.576289 1.44968 0.496948 1.24995 0.496948C1.05023 0.496948 0.858682 0.576289 0.717454 0.717517C0.576226 0.858745 0.496885 1.05029 0.496885 1.25002C0.496885 1.44974 0.576226 1.64129 0.717454 1.78252L3.94245 5.00002L0.717454 8.21752C0.647158 8.28724 0.591362 8.37019 0.553286 8.46158C0.515209 8.55298 0.495605 8.65101 0.495605 8.75002C0.495605 8.84903 0.515209 8.94706 0.553286 9.03845C0.591362 9.12984 0.647158 9.21279 0.717454 9.28252C0.787176 9.35281 0.870127 9.40861 0.961522 9.44669C1.05292 9.48476 1.15095 9.50437 1.24995 9.50437C1.34896 9.50437 1.44699 9.48476 1.53839 9.44669C1.62978 9.40861 1.71273 9.35281 1.78245 9.28252L4.99995 6.05752L8.21745 9.28252C8.28718 9.35281 8.37013 9.40861 8.46152 9.44669C8.55292 9.48476 8.65095 9.50437 8.74995 9.50437C8.84896 9.50437 8.94699 9.48476 9.03839 9.44669C9.12978 9.40861 9.21273 9.35281 9.28245 9.28252C9.35275 9.21279 9.40855 9.12984 9.44662 9.03845C9.4847 8.94706 9.5043 8.84903 9.5043 8.75002C9.5043 8.65101 9.4847 8.55298 9.44662 8.46158C9.40855 8.37019 9.35275 8.28724 9.28245 8.21752L6.05745 5.00002Z" fill={fill} />
      </svg>
   )
}

export default CloseIcon