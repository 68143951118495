import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box'

import MuiLink from '@mui/material/Link';

import Typography from '@mui/material/Typography'

const Link = styled((props) => (
   <MuiLink
      {...props}
   />
))(({ theme }) => ({
   display: 'flex',
   flexDirection: 'column',
   gap: '10px',
   color: 'black',
   textDecoration: 'none',
   transitionProperty: 'all',
   transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
   transitionDuration: '150ms',
   '&:active': {
      filter: 'brightness(0.7)',
   }
}));

function ContentCard({
   href = '#',
   image,
   name,
   redeemed
}) {
   return (
      <Link
         href={href}
      >
         <Box sx={{
            width: '35vw',
            height: '35vw',
            maxWidth: '180px',
            maxHeight: '180px',
            mt: '15px'
         }}>
            <img
               src={image}
               alt={image}
               style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: '20px',
                  objectFit: 'cover',
               }}
            />
         </Box>
         <div>
            <Typography variant='body1' sx={{ fontWeight: 700, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{name}</Typography>
            {/* <Typography variant='body2' sx={{ fontWeight: 400 }}>{redeemed}{" "}redeemed</Typography> */}
         </div>
      </Link>
   )
}

export default ContentCard