import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function TopMenu({
   button,
   open,
   anchorEl,
   handleClose
}) {
   const location = useLocation();
   const navigate = useNavigate();

   const handleLogout = () => {
      window.localStorage.clear()
      navigate('/login')
   }

   const menuItem = [
      {
         label: 'Profile',
         route: '/profile',
      },
      {
         label: 'Redeem History',
         route: '/redeem-history',
      },
   ]

   return (
      <>
         {button}
         <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
               'aria-labelledby': 'basic-button',
            }}
            elevation={0}
            PaperProps={{
               style: {
                  margin: '0 auto',
                  maxWidth: '480px',
                  width: '100%',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.08)',
               }
            }}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "center",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "center",
            }}
            sx={{
               '& .MuiPaper-root': {
                  left: '0px!important',
                  right: '0px!important'
               },
               '& .MuiMenuItem-root': {
                  justifyContent: 'center'
               }
            }}
         >
            {menuItem.map((item, i) => (
               <MenuItem
                  key={i}
                  onClick={() => { navigate(`${item.route}`) }}
                  sx={{
                     fontWeight: location.pathname === item.route ? 'bold' : 'normal',
                     color: location.pathname === item.route ? '#2F6CE1' : '#000000'
                  }}
               >
                  {item.label}
               </MenuItem>
            ))}
            <MenuItem onClick={handleLogout}>
               Logout
            </MenuItem>
         </Menu>
      </>
   )
}

export default TopMenu