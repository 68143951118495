import React from 'react'
import Box from '@mui/material/Box'

import TopNav from 'components/layout/navigation/TopNav'
import LoadingPage from 'components/loading/LoadingPage'

function MainContainer({
   children,
   isLoading = false,
}) {
   return (
      <Box sx={{ height: '100%' }}>
         {isLoading ? (
            <LoadingPage />
         ) : (
            <>
               <TopNav />
               <Box sx={{ m: '0px 40px', pt: '96px', pb: '80px', height: '100%' }}>
                  {children}
               </Box>
            </>
         )}
      </Box>
   )
}

export default MainContainer