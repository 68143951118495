import React from 'react'
import Box from '@mui/material/Box'

import ContentCard from 'components/card/ContentCard'
// import { games } from 'dummyData'

function ContentCards({
   games
}) {

   return (
      <div>
         <Box sx={{ display: 'flex', pb: '5px', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {games.map((game, i) => {
               return (
                  <Box key={i} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                     <ContentCard
                        href={`game/${game.id}`}
                        image={game.thumbnail}
                        name={game.name}
                        redeemed={game.redeemed || 99}
                     />
                  </Box>
               );
            })}
         </Box>
      </div>


   )
}

export default ContentCards