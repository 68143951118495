import React from 'react'

function ArrowUpIcon({
   width = "18",
   height = "10",
   fill = "black"
}) {
   return (
      <svg width={width} height={height} viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M1 1L8.59615 8.59615L16.9519 1" stroke={fill} strokeWidth="2" />
      </svg>
   )
}

export default ArrowUpIcon