import React, { useState } from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function ProfileForm({
   userEmail,
   agentRef,
   channelRef
}) {
   const [shortBio, setShortBio] = useState('');

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
         {/* <Box>
            <InputLabel htmlFor='ISPChannelRef' sx={{ fontWeight: 'bold', mb: '10px' }}>ISP Channel Ref.</InputLabel>
            <TextField
               disabled={true}
               id="ISPChannelRef"
               value={channelRef}
               variant="outlined"
               sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                     borderRadius: '10px',
                  }
               }}
            />
         </Box> */}

         {/* <Box>
            <InputLabel htmlFor='ISPAgentRef' sx={{ fontWeight: 'bold', mb: '10px' }}>ISP Agent Ref.</InputLabel>
            <TextField
               disabled={true}
               id="ISPAgentRef"
               value={agentRef}
               variant="outlined"
               sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                     borderRadius: '10px',
                  }
               }}
            />
         </Box> */}

         <Box>
            <InputLabel htmlFor='email' sx={{ fontWeight: 'bold', mb: '10px' }}>Email Address</InputLabel>
            <TextField
               disabled={true}
               id="email"
               value={userEmail}
               variant="outlined"
               sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                     borderRadius: '10px',
                  }
               }}
            />
         </Box>

         {/* <Box>
            <InputLabel htmlFor='short_bio' sx={{ fontWeight: 'bold', mb: '10px' }}>Short Bio</InputLabel>
            <TextField
               multiline
               rows={6}
               id="short_bio"
               placeholder='Give description of your bio here...'
               variant="outlined"
               onChange={(e) => setShortBio(e.target.value)}
               sx={{
                  width: '100%',
                  background: 'white',
                  '& .MuiInputBase-root': {
                     borderRadius: '10px',
                  }
               }}
            />
         </Box> */}
         {/* <Button
            disabled={!shortBio}
            variant='contained'
            sx={{
               ml: 'auto',
               color: 'white',
               backgroundColor: 'black',
               p: '4px 22px',
               borderRadius: '10px',
               '&:disabled': {
                  backgroundColor: '#FFEFC7',
               }
            }}
         >
            Save
         </Button> */}
      </Box>
   )
}

export default ProfileForm