import React, { useState, useEffect } from 'react'
import api from 'services/axios';
import Box from '@mui/material/Box'
import MainContainer from 'components/layout/container/MainContainer'
import SearchBar from 'components/input/SearchBar'
import HomeMain from 'views/Home/HomeMain/HomeMain'
import { useSnackbar } from 'context/SnackbarContext'

function Home() {

   const [games, setGames] = useState([]);
   const [searchableGames, setSearchableGames] = useState([]);
   const openSnackbar = useSnackbar();

   const getGames = async () => {
      try {
         const { data: { data: games } } = await api.get('/api/game', {
            headers: {
               'access-token': `${window.localStorage.getItem('access_token')}`
            }
         });
         setGames(games)
         setSearchableGames(games.map((item) => {
            return {
               id: item.id,
               label: item.name
            }
         }))
      } catch (error) {
         openSnackbar(error.response.data.message.toString().replace('-', ' '), 'error')
         window.location.replace('/login')
      }
   }

   useEffect(() => {
      getGames()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const handleSearch = async (value) => {
      try {
         const { data: { data: games } } = await api.get(`/api/game`, {
            headers: {
               'access-token': `${window.localStorage.getItem('access_token')}`
            },
            params: {
               "search[value]": value && value.label.toLowerCase() || ''
            }
         });
         setGames(games)
      } catch (error) {
         console.log('Error get games:::', error)
         openSnackbar(error.response.data.message.toString().replace('-', ' '), 'error')
         window.location.replace('/login')
      }
   }

   return (
      <MainContainer>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               justifyContent: 'center',
               mt: '40px',
               width: '100%',
               gap: '40px',
            }}
         >
            <SearchBar
               handleSearch={handleSearch}
               handleClear={getGames}
               searchableGames={searchableGames}
            />
            <HomeMain
               games={games}
            />
         </Box>
      </MainContainer>
   )
}

export default Home