function SearchIcon({
   width = '28',
   height = '29',
   fill = '#5D2FE1'
}) {
   return (
      <svg
         width={width}
         height={height}
         viewBox="0 0 28 29"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M27.3814 23.8112L21.6364 18.062C20.5294 16.9542 18.7339 16.9539 17.6264 18.0613V18.0613C16.5193 19.1684 16.5193 20.9634 17.6264 22.0705L23.3771 27.8211C24.0135 28.4575 25.061 28.4575 25.6975 27.8211L27.3814 26.1372C28.0179 25.4951 28.0179 24.4419 27.3814 23.8112Z"
            fill={fill}
         />
         <path
            d="M14.7462 17.2867C15.3263 17.8668 16.2668 17.8668 16.8469 17.2867V17.2867C17.427 16.7066 17.427 15.766 16.8469 15.1859L16.5934 14.9324C15.9625 14.3016 15.8957 13.3167 16.2607 12.5027C17.6406 9.4258 17.0716 5.68416 14.5462 3.15883C11.2853 -0.102095 5.9856 -0.102095 2.71904 3.15883C-0.54751 6.41975 -0.541878 11.7194 2.71904 14.986C5.24438 17.5113 8.98602 18.0804 12.0629 16.7005C12.8769 16.3355 13.8618 16.4023 14.4926 17.0331L14.7462 17.2867ZM4.34106 13.3415C1.97562 10.976 1.97562 7.14064 4.34106 4.78084C6.70649 2.4154 10.5419 2.4154 12.9017 4.78084C15.2671 7.14627 15.2671 10.9817 12.9017 13.3415C10.5419 15.7069 6.70649 15.7069 4.34106 13.3415Z"
            fill={fill}
         />
      </svg>

   )
}

export default SearchIcon