import React from 'react'
import styled from '@mui/material/styles/styled'
import MuiBox from '@mui/material/Box'

import CircleCheckIcon from 'assets/CircleCheckIcon'

const StyledBox = styled(MuiBox)`
   background: ${({ type }) => type === 'success' ? '#ACECAA' : '#FFD4BC'};
   padding: 8px 14px;
   border-radius: 40px;
   display: flex;
   align-items: center;
   gap: 10px;
   font-size: 12px;
`

function Status({
   type,
}) {
   const statusValue = `${type.substring(0, 1).toUpperCase()}${type.substring(1, type.length)}`

   return (
      <StyledBox type={type}>
         <CircleCheckIcon />
         {statusValue}
      </StyledBox>
   )
}

export default Status