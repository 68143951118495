import React from 'react'
import { styled } from '@mui/material/styles';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';

import CloseIcon from 'assets/CloseIcon'

const StyledSnackbar = styled(MuiSnackbar)`
   .MuiAlert-root {
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      color: #000000;
      font-size: 12px;
      font-weight: 500;
   }
   .MuiAlert-standardSuccess {
      background-color: #BAF1B9;
   }
   .MuiAlert-standardError {
      background-color: #FFD4BC;
   }

   @media (min-width: 480px){
      width: 430px;
      margin: 0 auto;
   }
`;

function Snackbar({
   open,
   message,
   severity,
   onClose,
   autoHideDuration
}) {
   return (
      <>
         <StyledSnackbar
            anchorOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
            open={open}
            onClose={onClose}
            autoHideDuration={autoHideDuration}
         >
            <MuiAlert
               icon={false}
               severity={severity}
               sx={{ width: '100%' }}
               action={(
                  <IconButton
                     aria-label="close"
                     onClick={onClose}
                  >
                     <CloseIcon />
                  </IconButton>
               )}
            >
               {message}
            </MuiAlert>
         </StyledSnackbar>
      </>
   )
}

export default Snackbar