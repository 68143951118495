import React from 'react'
import { format, parse } from 'date-fns'

import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import MuiPaper from '@mui/material/Paper'
import MuiTypography from '@mui/material/Typography'

import Status from 'components/status/Status'

import CalendarIcon from 'assets/CalendarIcon'

const StyledPaper = styled(MuiPaper)(({ theme }) => ({
   borderRadius: 0,
   padding: '22px 40px',
}));

const StyledKey = styled(MuiTypography)`
   font-weight: bold;
   font-size: 15px;
   color: #5D2FE1;
`
const StyledValue = styled(MuiTypography)`
   
`
const StyledSmallText = styled(MuiTypography)`
   font-size: 12px;
`

function RedeemHistoryCard({
   transactionId,
   gameType = 'PUBG Mobile',
   date,
   validUntil,
   status = 'success'
}) {
   const formattedDate = format(new Date(date), "EEEE, dd LLLL y KK:mm aa")
   const validDate = format(new Date(validUntil), 'dd LLLL y')
   const parsedDate = parse(validDate, 'dd LLLL y', new Date());
   const isDatePassed = parsedDate < new Date();

   return (
      <StyledPaper elevation={0}>
         <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '25px' }}>
            <Box sx={{ display: 'flex', gap: '10px' }}>
               <CalendarIcon />
               {formattedDate}
            </Box>
            <Status type={status} />
         </Box>
         <Box sx={{ display: 'flex', gap: '34px', mb: '15px' }}>
            <Box>
               <StyledKey>Transaction ID</StyledKey>
               <StyledValue>{transactionId}</StyledValue>
            </Box>
            <Box>
               <StyledKey>Game Type</StyledKey>
               <StyledValue>{gameType}</StyledValue>
            </Box>
         </Box>
         <StyledSmallText sx={{ color: isDatePassed ? '#D90000' : '#495ED4' }}>Valid until{" "}{validDate}</StyledSmallText>
      </StyledPaper>
   )
}

export default RedeemHistoryCard